import React, { useState } from 'react';
import { ModalHeader } from '@fingermarkglobal/cringer.components';
import { PrimaryButton } from '@fingermarkglobal/cringer.components';
import TropicRewardsImage from '../../../assets/images/TSC-Tropic-Rewards-Logo.png';
import AppSignupImage from '../../../assets/images/tsc-app-signup.webp';

export const LoginOrSkipModal = ({ t, onLoginClick, onSkipClick }) => {
  const [showAppSignup, setShowAppSignup] = useState(false);
  return (
    <div className="flex flex-col justify-center p-10">
      <img src={TropicRewardsImage} />
      {showAppSignup ? (
        <>
          <ModalHeader title={t('loyaltyNotAMemberSignUpQuestion')} />
          <div className="flex flex-col items-center justify-center max-w-48">
            <p className="text-4xl">{t('loyaltyNotAMemberSignUpAnswer')}</p>
            <img src={AppSignupImage} />
          </div>
          <div
            data-test="modal-footer"
            className={
              'flex flex-col items-center md:flex-nowrap p-3 md:p-8 border-t border-solid border-gray-300 rounded-b modal-footer gap-10'
            }
          >
            <PrimaryButton
              className={`footer-action-button modal-footer-button min-w-100`}
              onClick={() => setShowAppSignup(false)}
            >
              {t('loyaltyNotAMemberGoBackButton')}
            </PrimaryButton>
          </div>
        </>
      ) : (
        <>
          <ModalHeader title={t('loyaltyLoginOrSkipModalTitle')} />
          <div
            data-test="modal-footer"
            className={
              'flex flex-col items-center md:flex-nowrap p-3 md:p-8 border-t border-solid border-gray-300 rounded-b modal-footer gap-10'
            }
          >
            <PrimaryButton
              className={`footer-action-button modal-footer-button min-w-100`}
              onClick={onSkipClick}
            >
              {t('loyaltyLoginOrSkipModalSkipButtonText')}
            </PrimaryButton>
            <p className="text-3xl">OR</p>
            <PrimaryButton
              className={`footer-action-button modal-footer-button min-w-100`}
              onClick={onLoginClick}
            >
              {t('loyaltyLoginOrSkipModalLoginButtonText')}
            </PrimaryButton>
            <button
              className="text-2xl text-center"
              onClick={() => {
                setShowAppSignup(true);
              }}
            >
              <strong>{t('loyaltyNotAMemberSignUpQuestion')}</strong>
              <br />
              {t('loyaltyNotAMemberFindOutHowToJoin')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};
